
import { defineComponent, ref, onBeforeUnmount, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import axios from '@/http'

export default defineComponent({
	name: 'LoginPage',

	setup() {
		const phone = ref()
		const code = ref()
		const isFieldCode = ref(false)
		const isDisableFieldPhone = ref(false)
		const isDisableFieldCode = ref(false)
		const intervalTime = ref(30)
		const isInterval = ref(false)
		const isResetCode = ref(false)
		let interval: number | null = null
		const refFieldCode = ref()

		const store = useStore()
		const router = useRouter()

		async function apiCheckPhone(phone: string) {
			isDisableFieldPhone.value = true

			try {
				await axios.post('/auth/login', { phone: '7' + phone })
			} catch (e) {
				alert('Вы не зарегистрированы или у вас нет доступа')

				isDisableFieldPhone.value = false
				return
			}

			isFieldCode.value = true
			nextTick(() => refFieldCode.value.focus())
			startInterval()
		}

		async function apiCheckCode(code: string) {
			isDisableFieldCode.value = true

			isResetCode.value = false
			stopInterval()

			await store.dispatch('auth/loginAction', { code, phone: '7' + phone.value })
			isDisableFieldCode.value = false
			if (store.state.auth.isAuth) {
				if (store.state.orderId !== null) {
					const orderId = store.state.orderId
					store.commit('setOrderId', null)

					return router.push('/order-by-id/' + orderId)
				}

				return router.push('/')
			}

			alert('Код неверный')
		}

		function checkValidPhone(val: string): void {
			if (!Number(val) || val.length > 10) phone.value = phone.value.slice(0, -1)

			if (phone.value.length === 10) {
				apiCheckPhone(phone.value)
			}
		}

		function checkValidCode(val: string): void {
			if (!Number(val) || val.length > 4) code.value = code.value.slice(0, -1)

			if (code.value.length === 4) {
				apiCheckCode(code.value)
			}
		}

		function resetCode() {
			isResetCode.value = false
			checkValidPhone(phone.value)
		}

		function startInterval() {
			interval = setInterval(() => {
				if (intervalTime.value - 1 === -1) {
					isResetCode.value = true
					stopInterval()
				}
				intervalTime.value--
			}, 1000)

			isResetCode.value = false
			isInterval.value = true
		}

		function stopInterval() {
			if (interval !== null) clearInterval(interval)
			isInterval.value = false
			intervalTime.value = 30
			isDisableFieldPhone.value = false
		}

		onBeforeUnmount(() => stopInterval())

		return {
			refFieldCode,
			resetCode,
			isResetCode,
			intervalTime,
			isInterval,
			isDisableFieldPhone,
			isDisableFieldCode,
			checkValidPhone,
			checkValidCode,
			isFieldCode,
			code,
			phone
		}
	}
})
